import React, { useState, useEffect } from 'react';
import {
    Box,
    Link,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Button,
    Stack,
    Input,
    useBreakpointValue
} from "@chakra-ui/react";
import Card from "components/card/Card";
import moment from 'moment';

export default function EmployeeReport() {
    const [companies, setCompanies] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [companiesPerPage] = useState(10);
    const [searchTerm, setSearchTerm] = useState('');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchCompanies = async () => {
            try {
                const response = await fetch('https://api.cloudace.co.uk/fetch-expired-companies');
                if (response.ok) {
                    const data = await response.json();
                    setCompanies(data);
                } else {
                    console.error('Failed to fetch company data');
                }
            } catch (error) {
                console.error('Error fetching company data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchCompanies();
    }, []);

    const indexOfLastCompany = currentPage * companiesPerPage;
    const indexOfFirstCompany = indexOfLastCompany - companiesPerPage;

    const filteredCompanies = companies.filter(company =>
        company.cCompName.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const currentCompanies = filteredCompanies.slice(indexOfFirstCompany, indexOfLastCompany);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
        setCurrentPage(1);
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear().toString();
        return `${day}/${month}/${year}`;
    };

    const exportToCsv = () => {
        const csvContent = [
            'SR,Company Name,Contact Person,Email Address,Mobile Number,Company House Number,DOJ,Address,City,Country,Membership Start Date,Membership Amount'
        ];

        currentCompanies.forEach((company, index) => {
            const rowData = [
                indexOfFirstCompany + index + 1,
                company.cCompName,
                company.cContactPerson,
                company.cCompEmail,
                company.cContractNumber,
                company.cCompHoNumber,
                formatDate(company.cDOJ),
                escapeCsvField(company.cAddress),
                company.cCity,
                company.cCountry,
                formatDate(company.mStartDate), // Membership start date
                company.mAmnt // Membership amount
            ];
            const csvRow = rowData.map(escapeCsvField).join(',');
            csvContent.push(csvRow);
        });

        const csvString = csvContent.join('\n');
        const blob = new Blob([csvString], { type: 'text/csv' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'company_list.csv';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };

    const escapeCsvField = (value) => {
        if (typeof value !== 'string') {
            return value;
        }
        return `"${value.replace(/"/g, '""')}"`;
    };


    const inputWidth = useBreakpointValue({ base: '100%', md: 'auto' });

    return (
        <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
            <Link
                color='black'
                bg='inherit'
                borderRadius='inherit'
                fontWeight='bold'
                fontSize={{ base: "24px", md: "34px" }}
                _active={{
                    bg: 'inherit',
                    transform: 'none',
                    borderColor: 'transparent'
                }}
                _focus={{
                    boxShadow: 'none'
                }}>
                Expiry Report
            </Link>
            <Card>
                <Stack spacing={4} direction="row" align="center">

                    <Button variant="brand" size="sm" onClick={exportToCsv}>
                        Export CSV
                    </Button>
                    <Input
                        type="text"
                        value={searchTerm}
                        onChange={handleSearchChange}
                        placeholder="Search by name or email"
                        maxW={inputWidth}
                    />
                </Stack>
                <Box overflowX="auto">
                    <Table variant="striped" colorScheme="brand" mt={4} minW="100%">
                        <Thead>
                            <Tr>
                                <Th>SR</Th>
                                <Th>Company Name</Th>
                                <Th>Contact Person</Th>
                                <Th>Email Address</Th>
                                <Th>Mobile Number</Th>
                                <Th>Last Invoice Date</Th>
                            </Tr>
                        </Thead>
                        <Tbody>

                             {currentCompanies.map((company, index) => (
                            <Tr key={company.id}>
                              <Td>{indexOfFirstCompany + index + 1}</Td>
                              <Td>{company.cCompName}</Td>
                              <Td>{company.cContactPerson}</Td>
                              <Td>{company.cCompEmail}</Td>
                              <Td>{company.cContractNumber}</Td>
                              <Td>{formatDate(company.lastInvoiceCreateTime)}</Td>
                            </Tr>
                          ))}
                        </Tbody>
                    </Table>
                </Box>
                <Stack direction="row" justify="center" mt={4}>
                    {Array.from({ length: Math.ceil(companies.length / companiesPerPage) }, (_, index) => (
                        <Button
                            key={index}
                            variant={currentPage === index + 1 ? "solid" : "outline"}
                            onClick={() => paginate(index + 1)}
                        >
                            {index + 1}
                        </Button>
                    ))}
                </Stack>
            </Card>
        </Box>
    );
}
