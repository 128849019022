import React, { useState, useEffect } from 'react';
import {
    Box,
    Link,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Button,
    Stack,
    Input,
    useBreakpointValue
} from "@chakra-ui/react";
import Card from "components/card/Card";
import moment from 'moment';

export default function EmployeeReport() {
    const [companies, setCompanies] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [companiesPerPage] = useState(10);
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        // Fetch company data from the server
        const fetchCompanies = async () => {
            try {
                const response = await fetch('https://api.cloudace.co.uk/get-company');
                if (response.ok) {
                    const data = await response.json();
                    setCompanies(data);
                } else {
                    console.error('Failed to fetch company data');
                }
            } catch (error) {
                console.error('Error fetching company data:', error);
            }
        };

        fetchCompanies();
    }, []);

    const indexOfLastCompany = currentPage * companiesPerPage;
    const indexOfFirstCompany = indexOfLastCompany - companiesPerPage;
    const currentCompanies = companies.filter(company =>
        company.cCompName.toLowerCase().includes(searchTerm.toLowerCase())
    ).slice(indexOfFirstCompany, indexOfLastCompany);

    // Change page
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    // Handle search input change
    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
        setCurrentPage(1); // Reset pagination to first page when searching
    };

    // Format date function
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear().toString();
        return `${day}/${month}/${year}`;
    };

    // Function to export data to CSV
    const exportToCsv = () => {
        const csvContent = [
            'SR,Company Name,Contact Person,Email Address,Mobile Number,Company House Number,DOJ,Address,City,Country'
        ];

        currentCompanies.forEach((company, index) => {
            // Prepare row data
            const rowData = [
                indexOfFirstCompany + index + 1,
                company.cCompName,
                company.cContactPerson,
                company.cCompEmail,
                company.cContractNumber,
                company.cCompHoNumber,
                formatDate(company.cDOJ),
                escapeCsvField(company.cAddress), // Escape address field
                company.cCity,
                company.cCountry
            ];
            // Join row data into a CSV row
            const csvRow = rowData.map(escapeCsvField).join(',');
            csvContent.push(csvRow);
        });

        // Join all rows with newline characters
        const csvString = csvContent.join('\n');

        // Create CSV blob and download link
        const blob = new Blob([csvString], { type: 'text/csv' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'company_list.csv';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };

    const escapeCsvField = (value) => {
        if (typeof value !== 'string') {
            return value;
        }
        // Enclose in quotes and escape quotes within the value
        return `"${value.replace(/"/g, '""')}"`;
    };

    const inputWidth = useBreakpointValue({ base: '100%', md: 'auto' });

    return (
        <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
            <Link
                color='black'
                bg='inherit'
                borderRadius='inherit'
                fontWeight='bold'
                fontSize={{ base: "24px", md: "34px" }}
                _active={{
                    bg: 'inherit',
                    transform: 'none',
                    borderColor: 'transparent'
                }}
                _focus={{
                    boxShadow: 'none'
                }}>
                Company Report
            </Link>
            <Card>
                <Stack spacing={4} direction="row" align="center">

                    <Button variant="brand" size="sm" onClick={exportToCsv}>
                        Export CSV
                    </Button>
                    <Input
                        type="text"
                        value={searchTerm}
                        onChange={handleSearchChange}
                        placeholder="Search by name or email"
                        maxW={inputWidth}
                    />
                </Stack>
                <Box overflowX="auto">
                    <Table variant="striped" colorScheme="brand" mt={4} minW="100%">
                        <Thead>
                            <Tr>
                                <Th>SR</Th>
                                <Th>Company Name</Th>
                                <Th>Contact Person</Th>
                                <Th>Email Address</Th>
                                <Th>Mobile Number</Th>
                                <Th>Company's House Number</Th>
                                <Th>DOJ</Th>
                                <Th>Address</Th>
                                <Th>City</Th>
                                <Th>Country</Th>
                            </Tr>
                        </Thead>
                        <Tbody>

                            {currentCompanies.map((company, index) => (
                                <Tr key={company.id}>
                                    <Td>{indexOfFirstCompany + index + 1}</Td>
                                    <Td>{company.cCompName}</Td>
                                    <Td>{company.cContactPerson}</Td>
                                    <Td>{company.cCompEmail}</Td>
                                    <Td>{company.cContractNumber}</Td>
                                    <Td>{company.cCompHoNumber}</Td>
                                    <Td>{formatDate(company.cDOJ)}</Td>
                                    <Td>{company.cAddress}</Td>
                                    <Td>{company.cCity}</Td>
                                    <Td>{company.cCountry}</Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                </Box>
                <Stack direction="row" justify="center" mt={4}>
                    {Array.from({ length: Math.ceil(companies.length / companiesPerPage) }, (_, index) => (
                        <Button
                            key={index}
                            variant={currentPage === index + 1 ? "solid" : "outline"}
                            onClick={() => paginate(index + 1)}
                        >
                            {index + 1}
                        </Button>
                    ))}
                </Stack>
            </Card>
        </Box>
    );
}
