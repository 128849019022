import {
  Box,
  SimpleGrid,
  useColorModeValue,
  Link,
  Grid,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  Button,
  Stack,
  Menu,
  MenuItem,
  MenuList,
  Badge,
  MenuButton
} from "@chakra-ui/react";
import MiniStatistics from "components/card/MiniStatistics";
import IconBox from "components/icons/IconBox";
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Card from "components/card/Card"
import { MdPerson3 } from "react-icons/md";
import { useHistory } from 'react-router-dom';
import moment from 'moment';


export default function UserReports() {
  const history = useHistory(); // Move useHistory outside useEffect

  const [employeeCount, setEmployeeCount] = useState(0);
  const [leaveCount, setLeaveCount] = useState(0);
  const [attendanceCount, setAttendanceCount] = useState(0);

  useEffect(() => {
    const adminId = sessionStorage.getItem('adminId');
    if (!adminId) {
      history.push('/auth/sign-in');
      return;
    }

    const fetchData = async () => {
      try {
        // Fetch employee count
        const employeeResponse = await axios.get(`https://api.cloudace.co.uk/get-employee-count?adminId=${adminId}`);
        setEmployeeCount(employeeResponse.data.employeeCount);

        // Fetch attendance count for today
        const attendanceResponse = await axios.get(`https://api.cloudace.co.uk/get-atds-count?adminId=${adminId}`);
        setAttendanceCount(attendanceResponse.data.attendanceCount);

        // Fetch leave count
        const leaveResponse = await axios.get(`https://api.cloudace.co.uk/get-leave-count?adminId=${adminId}`);
        setLeaveCount(leaveResponse.data.leaveCount);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData(); // Trigger data fetching when component mounts
  }, [history]); // Include history in the dependency array if needed

  // Chakra Color Mode
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");

  const [companies, setCompanies] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [companiesPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const adminId = sessionStorage.getItem('adminId');


    const fetchCompanies = async () => {
      try {
        const response = await fetch(`https://api.cloudace.co.uk/get-atds?adminId=${adminId}`);
        if (response.ok) {
          const data = await response.json();
          setCompanies(data);
        } else {
          console.error('Failed to fetch company data');
        }
      } catch (error) {
        console.error('Error fetching company data:', error);
      }
    };

    fetchCompanies();
  }, []);

  const indexOfLastCompany = currentPage * companiesPerPage;
  const indexOfFirstCompany = indexOfLastCompany - companiesPerPage;
  const currentCompanies = companies
    .filter(company =>
      company &&
      company.eFname &&
      company.eLname &&
      company.lName &&
      company.aDate &&
      company.aInTime &&
      company.aOutTime &&
      company.aInTimeRemark &&
      company.aDefaultBreak
    )
    .filter(company =>
      company.eFname.toLowerCase().includes(searchTerm.toLowerCase()) ||
      company.eLname.toLowerCase().includes(searchTerm.toLowerCase()) ||
      company.lName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      company.aDate.toLowerCase().includes(searchTerm.toLowerCase()) ||
      company.aInTime.toLowerCase().includes(searchTerm.toLowerCase()) ||
      company.aOutTime.toLowerCase().includes(searchTerm.toLowerCase()) ||
      company.aInTimeRemark.toLowerCase().includes(searchTerm.toLowerCase()) ||
      company.aDefaultBreak.toString().toLowerCase().includes(searchTerm.toLowerCase())
    )
    .slice(indexOfFirstCompany, indexOfLastCompany);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const formatDate = (timestamp) => {
    const formattedDate = moment(timestamp).format('DD-MM-YYYY'); // Adjust date format based on the API response
    return formattedDate;
  };


  return (
    <Box pt={{ base: "130px", md: "px", xl: "80px" }}>
      <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} gap='20px' mb='20px'>
        <MiniStatistics
          startContent={
            <IconBox w='56px' h='56px' bg={boxBg} icon={<MdPerson3 w='32px' h='32px' color='#ffdd3c' />} />
          }
          name='Total Company'
          value={employeeCount}
        />
        <MiniStatistics
          startContent={
            <IconBox w='56px' h='56px' bg={boxBg} icon={<MdPerson3 w='32px' h='32px' color='#6FC276' />} />
          }
          name='Total Amount Rcv'
          value={`£${leaveCount}`}
        />
        <MiniStatistics
          startContent={
            <IconBox w='56px' h='56px' bg={boxBg} icon={<MdPerson3 w='32px' h='32px' color='#ff2c2c' />} />
          }
          name='Company Account Expierd'
          value={leaveCount}
        />
      </SimpleGrid>


    </Box>
  );
}
