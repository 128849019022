import {
    Box,
    Grid,
    FormControl,
    FormLabel,
    Input,
    Select,
    Button,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import { useHistory, useParams } from 'react-router-dom';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import axios from 'axios';

export default function Overview() {
    const [employee, setEmployee] = useState(null);
    const [formData, setFormData] = useState({
        cCompName: '',
        cCompHoNumber: '',
        cContactPerson: '',
        cContractNumber: '',
        cCompEmail: '',
        cAddress: '',
        cCity: '',
        cState: '',
        cCountry: '',
        cZip: '',
        cDOJ: '', // Make sure cDOJ is of format YYYY-MM-DD
        cPassword: ''
    });

    const { id } = useParams(); // Extract the employee ID from the URL parameters

    useEffect(() => {
        const fetchEmployee = async () => {
            try {
                const response = await fetch(`https://api.cloudace.co.uk/company/${id}`);
                if (response.ok) {
                    const data = await response.json();
                    setEmployee(data);
                    setFormData({
                        cCompName: data.cCompName || '',
                        cCompHoNumber: data.cCompHoNumber || '',
                        cContactPerson: data.cContactPerson || '',
                        cContractNumber: data.cContractNumber || '',
                        cCompEmail: data.cCompEmail || '',
                        cAddress: data.cAddress || '',
                        cCity: data.cCity || '',
                        cState: data.cState || '',
                        cCountry: data.cCountry || '',
                        cZip: data.cZip || '',
                        cDOJ: data.cDOJ ? data.cDOJ.substring(0, 10) : '', // Ensure date format is YYYY-MM-DD
                        cPassword: data.cPassword || ''
                    });
                } else {
                    console.error('Failed to fetch employee data');
                }
            } catch (error) {
                console.error('Error fetching employee data:', error);
            }
        };

        fetchEmployee();
    }, [id]);

    const history = useHistory();

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await saveFormDataToServer(formData);
            history.push('/admin/company');
        } catch (error) {
            console.error('Error saving data:', error);
        }
    };

    const saveFormDataToServer = async (data) => {
        const response = await fetch(`https://api.cloudace.co.uk/edit-company/${id}`, {
            method: 'PUT', // Assuming you are using PUT method to update data
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });

        if (!response.ok) {
            throw new Error('Failed to save data');
        }
    };


    return (
        <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
            <Card>
                <form onSubmit={handleSubmit}>
                    <Grid templateColumns="repeat(2, 1fr)" gap={4}>
                        <FormControl id="employee">
                            <FormLabel>Company Name:</FormLabel>
                            <Input
                                type="text"
                                name="cCompName"
                                value={formData.cCompName}
                                onChange={handleChange}
                                required
                                className='form-control'
                            />
                        </FormControl>

                        <FormControl id="employee">
                            <FormLabel>Companies House Number:</FormLabel>
                            <Input
                                type="text"
                                name="cCompHoNumber"
                                value={formData.cCompHoNumber}
                                onChange={handleChange}
                                className='form-control'
                            />
                        </FormControl>

                        <FormControl id="employee">
                            <FormLabel>Contact Name:</FormLabel>
                            <Input
                                type="text"
                                name="cContactPerson"
                                value={formData.cContactPerson}
                                onChange={handleChange}
                                required
                                className='form-control'
                            />
                        </FormControl>

                        <FormControl id="employee">
                            <FormLabel>Contact Number:</FormLabel>
                            <Input
                                type="text"
                                name="cContractNumber"
                                value={formData.cContractNumber}
                                onChange={handleChange}
                                required
                                className='form-control'
                            />
                        </FormControl>

                        <FormControl id="employee">
                            <FormLabel>Email Address:</FormLabel>
                            <Input
                                type="email"
                                name="cCompEmail"
                                value={formData.cCompEmail}
                                onChange={handleChange}
                                required
                                className='form-control'
                            />
                        </FormControl>

                        <FormControl id="employee">
                            <FormLabel>Address:</FormLabel>
                            <Input
                                type="text"
                                name="cAddress"
                                value={formData.cAddress}
                                onChange={handleChange}
                                required
                                className='form-control'
                            />
                        </FormControl>

                        <FormControl id="employee">
                            <FormLabel>City:</FormLabel>
                            <Input
                                type="text"
                                name="cCity"
                                value={formData.cCity}
                                onChange={handleChange}
                                required
                                className='form-control'
                            />
                        </FormControl>

                        <FormControl id="employee">
                            <FormLabel>Country:</FormLabel>
                            <Input
                                type="text"
                                name="cCountry"
                                value={formData.cCountry}
                                onChange={handleChange}
                                required
                                className='form-control'
                            />
                        </FormControl>

                        <FormControl id="employee">
                            <FormLabel>Post Code:</FormLabel>
                            <Input
                                type="text"
                                name="cZip"
                                value={formData.cZip}
                                onChange={handleChange}
                                required
                                className='form-control'
                            />
                        </FormControl>

                        <FormControl id="employee">
                            <FormLabel>Date of Joining:</FormLabel>
                            <Input
                                type="date"
                                name="cDOJ"
                                value={formData.cDOJ}
                                onChange={handleChange}
                                required
                                className='form-control'
                            />
                        </FormControl>

                        <FormControl id="employee">
                            <FormLabel>Password:</FormLabel>
                            <Input
                                type="text"
                                name="cPassword"
                                value={formData.cPassword}
                                onChange={handleChange}
                                required
                                className='form-control'
                            />
                        </FormControl>

                    </Grid>
                    <Button mt={4} colorScheme="brand" type="submit">
                        Submit
                    </Button>
                </form>
            </Card>
        </Box>
    );
}
