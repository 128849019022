
import {
    Box,
    Link,
    Grid,
    Form,
    FormControl,
    FormLabel,
    Input,
    Select,
    Textarea,
    FormErrorMessage,
    FormHelperText,
    Button,
} from "@chakra-ui/react";
import Card from "components/card/Card"
import { useHistory } from 'react-router-dom';
import moment from 'moment'; // Import moment for date/time formatting

import React, { useState, useEffect } from 'react';
import { MdArrowDropDown, MdForkRight } from "react-icons/md";
import axios from 'axios';

export default function Overview() {
    const [formData, setFormData] = useState({
        cCompName: '',
        cContactPerson: '',
        cCompHoNumber: '',
        cContractNumber: '',
        cCompEmail: '',
        cAddress: '',
        cCity: '',
        cState: '',
        cCountry: '',
        cZip: '',
        cDOJ: '',
        cPassword: ''
    });

    const [emailError, setEmailError] = useState('');

    const history = useHistory();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await saveFormDataToServer(formData);
            history.push('/admin/company');
        } catch (error) {
            console.error('Error saving data:', error);
            setEmailError('Email is already registered. Please enter a new email.');
        }
    };

    const saveFormDataToServer = async (data) => {
        const response = await fetch('https://api.cloudace.co.uk/add-company', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });

        if (!response.ok) {
            const errorData = await response.json();
            if (response.status === 400 && errorData.message === 'Email already exists') {
                setEmailError('Email is already registered. Please enter a new email.');
            } else {
                throw new Error('Failed to save data');
            }
        }
    };

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
        setEmailError(''); // Clear email error message when input changes
    };

    useEffect(() => {
        const adminId = sessionStorage.getItem('adminId');

        if (adminId === null) {
            window.location.href = 'sing-in';
        }
    }, []);



    return (
        <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
            <Card>
                <form onSubmit={handleSubmit}>

                    <Grid templateColumns="repeat(2, 1fr)" gap={4}>
                        <FormControl id="employee">
                            <FormLabel>Company Name:</FormLabel>
                            <Input
                                type="text"
                                name="cCompName"
                                value={formData.cCompName}
                                onChange={handleChange}
                                required
                                className='form-control'
                            />
                        </FormControl>

                        <FormControl id="employee">
                            <FormLabel>Companies House Number:</FormLabel>
                            <Input
                                type="text"
                                name="cCompHoNumber"
                                value={formData.cCompHoNumber}
                                onChange={handleChange}
                                className='form-control'
                            />
                        </FormControl>

                        <FormControl id="employee">
                            <FormLabel>Contact Name:</FormLabel>
                            <Input
                                type="text"
                                name="cContactPerson"
                                value={formData.cContactPerson}
                                onChange={handleChange}
                                required
                                className='form-control'
                            />
                        </FormControl>

                        <FormControl id="employee">
                            <FormLabel>Contact Number:</FormLabel>
                            <Input
                                type="text"
                                name="cContractNumber"
                                value={formData.cContractNumber}
                                onChange={handleChange}
                                required
                                className='form-control'
                            />
                        </FormControl>

                        <FormControl id="employee">
                            <FormLabel>Email Address:</FormLabel>
                            <Input
                                type="email"
                                name="cCompEmail"
                                value={formData.cCompEmail}
                                onChange={handleChange}
                                required
                                className="form-control"
                            />
                            {emailError && <div className="text-danger">{emailError}</div>}

                        </FormControl>

                        <FormControl id="employee">
                            <FormLabel>Address:</FormLabel>
                            <Input
                                type="text"
                                name="cAddress"
                                value={formData.cAddress}
                                onChange={handleChange}
                                required
                                className='form-control'
                            />

                        </FormControl>

                        <FormControl id="employee">
                            <FormLabel>City:</FormLabel>
                            <Input
                                type="text"
                                name="cCity"
                                value={formData.cCity}
                                onChange={handleChange}
                                required
                                className='form-control'
                            />
                        </FormControl>

                        <FormControl id="employee">
                            <FormLabel>Country:</FormLabel>
                            <Input
                                type="text"
                                name="cCountry"
                                value={formData.cCountry}
                                onChange={handleChange}
                                required
                                className='form-control'
                            />
                        </FormControl>

                        <FormControl id="employee">
                            <FormLabel>Post Code:</FormLabel>
                            <Input
                                type="text"
                                name="cZip"
                                value={formData.cZip}
                                onChange={handleChange}
                                required
                                className='form-control'
                            />
                        </FormControl>

                        <FormControl id="employee">
                            <FormLabel>Date of Joining:</FormLabel>
                            <Input
                                type="date"
                                name="cDOJ"
                                value={formData.cDOJ}
                                onChange={handleChange}
                                required
                                className='form-control'
                            />
                        </FormControl>

                        <FormControl id="employee">
                            <FormLabel>Password:</FormLabel>
                            <Input
                                type="text"
                                name="cPassword"
                                value={formData.cPassword}
                                onChange={handleChange}
                                required
                                className='form-control'
                            />
                        </FormControl>


                    </Grid>

                    <Button
                        mt={4}
                        colorScheme="brand"
                        type="submit"
                    >
                        Submit
                    </Button>
                </form>
            </Card>
        </Box >

    );
}
