import {
    Box,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Button,
    Stack,
    Menu,
    MenuItem,
    MenuList,
    MenuButton
} from "@chakra-ui/react";
import { NavLink } from 'react-router-dom';
import Card from "components/card/Card";
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { MdArrowDropDown } from "react-icons/md";

export default function Overview() {
    const [memberships, setMemberships] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [membershipsPerPage] = useState(10);
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        const adminId = sessionStorage.getItem('adminId');

        if (!adminId) {
            window.location.href = '/login';
        }

        const fetchMemberships = async () => {
            try {
                const response = await fetch('https://api.cloudace.co.uk/get-membership');
                if (response.ok) {
                    const data = await response.json();
                    setMemberships(data);
                } else {
                    console.error('Failed to fetch membership data');
                }
            } catch (error) {
                console.error('Error fetching membership data:', error);
            }
        };

        fetchMemberships();
    }, []);

    const indexOfLastMembership = currentPage * membershipsPerPage;
    const indexOfFirstMembership = indexOfLastMembership - membershipsPerPage;
    const currentMemberships = memberships.filter(membership =>
        membership && membership.cCompName && membership.cCompName.toLowerCase().includes(searchTerm.toLowerCase())
    ).slice(indexOfFirstMembership, indexOfLastMembership);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
        setCurrentPage(1);
    };

    const formatDate = (timestamp) => {
        const date = new Date(timestamp);
        return moment(date).format('DD-MM-YYYY');
    };

    const handleDeleteMembership = async (id) => {
        try {
            await fetch(`https://api.cloudace.co.uk/delete-membership/${id}`, {
                method: 'DELETE'
            });
            setMemberships(memberships.filter(membership => membership.id !== id));
            window.location.reload();
        } catch (error) {
            console.error('Error deleting membership:', error);
        }
    };

    const totalMemberships = memberships.filter(membership =>
        membership && membership.cCompName && membership.cCompName.toLowerCase().includes(searchTerm.toLowerCase())
    ).length;

    const totalPages = Math.ceil(totalMemberships / membershipsPerPage);

    const renderPageNumbers = () => {
        const pageNumbers = [];
        const maxVisiblePages = 5;

        if (totalPages <= maxVisiblePages) {
            for (let i = 1; i <= totalPages; i++) {
                pageNumbers.push(
                    <Button
                        key={i}
                        variant="outline"
                        colorScheme={currentPage === i ? "blue" : "gray"}
                        onClick={() => paginate(i)}
                    >
                        {i}
                    </Button>
                );
            }
        } else {
            const startPage = Math.max(1, currentPage - 2);
            const endPage = Math.min(totalPages, currentPage + 2);

            for (let i = startPage; i <= endPage; i++) {
                pageNumbers.push(
                    <Button
                        key={i}
                        variant="outline"
                        colorScheme={currentPage === i ? "blue" : "gray"}
                        onClick={() => paginate(i)}
                    >
                        {i}
                    </Button>
                );
            }

            if (currentPage > 3) {
                pageNumbers.unshift(
                    <Button
                        key={1}
                        variant="outline"
                        colorScheme={currentPage === 1 ? "blue" : "gray"}
                        onClick={() => paginate(1)}
                    >
                        1
                    </Button>
                );
                if (currentPage > 4) {
                    pageNumbers.splice(1, 0, <span key="ellipsis1">...</span>);
                }
            }

            if (currentPage < totalPages - 2) {
                pageNumbers.push(
                    <span key="ellipsis2">...</span>,
                    <Button
                        key={totalPages}
                        variant="outline"
                        colorScheme={currentPage === totalPages ? "blue" : "gray"}
                        onClick={() => paginate(totalPages)}
                    >
                        {totalPages}
                    </Button>
                );
            }
        }

        return pageNumbers;
    };

    return (
        <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
            <Card>
                <Stack spacing={4} direction="row" align="center">
                    <Button variant="brand" size="sm">
                        <NavLink to='invoice-add' fontSize="sm">Add Membership</NavLink>
                    </Button>
                </Stack>
                <br />
                <input
                    type="text"
                    placeholder="Search memberships"
                    value={searchTerm}
                    onChange={handleSearchChange}
                />
                <br />
                <Table variant="striped" colorScheme="brand">
                    <Thead>
                        <Tr>
                            <Th>SR</Th>
                            <Th>Company Name</Th>
                            <Th>Start Date</Th>
                            <Th>Amount</Th>
                            <Th>Created Date</Th>
                            <Th>Action</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {currentMemberships.map((membership, index) => (
                            <Tr key={membership.id}>
                                <Td>{indexOfFirstMembership + index + 1}</Td>
                                <Td>{membership.cCompName}</Td>
                                <Td>{formatDate(membership.mStartDate)}</Td>
                                <Td>{membership.mAmnt}</Td>
                                <Td>{formatDate(membership.mCreateTime)}</Td>
                                <Td>
                                    <Menu>
                                        <MenuButton as={Button} rightIcon={<MdArrowDropDown />}>
                                            Actions
                                        </MenuButton>
                                        <MenuList>
                                            <NavLink to={`invoice-print/${membership.id}`}><MenuItem>Print</MenuItem></NavLink>
                                            <MenuItem onClick={() => handleDeleteMembership(membership.id)}>Delete</MenuItem>
                                        </MenuList>
                                    </Menu>
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
                <Stack direction="row" justify="center" mt={4}>
                    <Button
                        onClick={() => paginate(currentPage - 1)}
                        disabled={currentPage === 1}
                    >
                        Previous
                    </Button>
                    {renderPageNumbers()}
                    <Button
                        onClick={() => paginate(currentPage + 1)}
                        disabled={currentPage === totalPages}
                    >
                        Next
                    </Button>
                </Stack>
            </Card>
        </Box>
    );
}
