import {
  Box,
  Grid,
  FormControl,
  FormLabel,
  Input,
  Select,
  Button,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import { useHistory, useParams } from 'react-router-dom';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import axios from 'axios';

function InvoicePrint({ history }) {
  const { id } = useParams(); // Extract the membership ID from URL params
  const [membership, setMembership] = useState(null);
  const [previousPageUrl, setPreviousPageUrl] = useState(null);

  useEffect(() => {
    // Store the previous page URL when the component mounts
    setPreviousPageUrl(document.referrer);

    // Fetch membership details based on the ID from the API
    const fetchMembershipDetails = async () => {
      try {
        const response = await fetch(`https://api.cloudace.co.uk/fetch-membership/${id}`);
        if (response.ok) {
          const data = await response.json();
          setMembership(data);
        } else {
          console.error('Failed to fetch membership details');
        }
      } catch (error) {
        console.error('Error fetching membership details:', error);
      }
    };

    fetchMembershipDetails();
  }, [id]); // Dependency array ensures this effect runs when ID changes

  useEffect(() => {
    // Trigger the print dialog when the component mounts
    window.print();

    // Listen for the afterprint event to redirect back to the previous page
    const handleAfterPrint = () => {
      redirectToPreviousPage();
    };

    window.addEventListener('afterprint', handleAfterPrint);

    return () => {
      window.removeEventListener('afterprint', handleAfterPrint);
    };
  }, []); // Dependency array ensures this effect runs only on component mount

  const redirectToPreviousPage = () => {
    // Redirect back to the previous page URL
    if (previousPageUrl) {
      history.push(previousPageUrl);
    } else {
      // If no previous page URL is available, fallback to a default route
      history.push('/');
    }
  };

  if (!membership) {
    return <div>Loading...</div>;
  }
  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleDateString();
  };

  return (
    <div className="print-invoice mt-4">
      <div className="container mt-6 mb-7">
        <div className="row justify-content-center">
          <div className="col-lg-12 col-xl-7">
            <div className="card">
              <div className="card-body p-5">
                <h2>
                  Hey {membership.cCompName},
                </h2>
                <p className="fs-sm">
                  This is the receipt for a payment of <strong>£ {membership.mAmnt}</strong> you made to Cloud Ace Services Limited.
                </p>
        
                <div className="border-top border-gray-200 pt-4 mt-4">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="text-muted mb-2">Payment No.</div>
                      <strong>#{membership.cCompName}</strong>
                    </div>
                    <div className="col-md-6 text-md-end">
                      <div className="text-muted mb-2">Payment Date</div>
                      <strong>{formatDate(membership.mCreateTime)}</strong>
                    </div>
                  </div>
                </div>
        
                <div className="border-top border-gray-200 mt-4 py-4">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="text-muted mb-2">Client</div>
                      <strong>
                      {membership.cCompName}
                      </strong>
                      <p className="fs-sm">
                      {membership.cAddress}
                        <br />
                        <a href="#!" className="text-purple">{membership.cCompEmail}</a>
                      </p>
                    </div>
                    <div className="col-md-6 text-md-end">
                      <div className="text-muted mb-2">Payment To</div>
                      <strong>
                      Cloud Ace Services Limited
                      </strong>
                      <p className="fs-sm">
                        (Your Address)
                        <br />
                        <a href="#!" className="text-purple">info@cloudace.co.uk</a>
                      </p>
                    </div>
                  </div>
                </div>
        
                <table className="table border-bottom border-gray-200 mt-3">
                  <thead>
                    <tr>
                      <th scope="col" className="fs-sm text-dark text-uppercase-bold-sm px-0">Description</th>
                      <th scope="col" className="fs-sm text-dark text-uppercase-bold-sm text-end px-0">Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="px-0">CloudACe HR Solution</td>
                      <td className="text-end px-0">£ {membership.mAmnt}</td>
                    </tr>
                    
                  </tbody>
                </table>
        
                <div className="mt-5">
                 
                  
                  <div className="d-flex justify-content-end mt-3">
                    <h5 className="me-3">Total:</h5>
                    <h5 className="text-success">£ {membership.mAmnt}</h5>
                  </div>
                </div>
              </div>
             
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InvoicePrint;
