import {
    Box,
    Link,
    Grid,
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    Button,
    Stack,
    Menu,
    MenuItem,
    MenuList,
    Badge,
    MenuButton
} from "@chakra-ui/react";
import { NavLink } from 'react-router-dom';
import Card from "components/card/Card";
import moment from 'moment'; // Import moment for date/time formatting

import React, { useState, useEffect, } from 'react';
import { MdArrowDropDown, MdForkRight } from "react-icons/md";

export default function Overview() {
    const [companies, setCompanies] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [companiesPerPage] = useState(10);
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        const adminId = sessionStorage.getItem('adminId');

        if (!adminId) {
            window.location.href = '/login';
        }

        const fetchCompanies = async () => {
            try {
                const response = await fetch(`https://api.cloudace.co.uk/get-company`);
                if (response.ok) {
                    const data = await response.json();
                    setCompanies(data);
                } else {
                    console.error('Failed to fetch company data');
                }
            } catch (error) {
                console.error('Error fetching company data:', error);
            }
        };

        fetchCompanies();
    }, []);

    const indexOfLastCompany = currentPage * companiesPerPage;
    const indexOfFirstCompany = indexOfLastCompany - companiesPerPage;
    const currentCompanies = companies.filter(company =>
        company.cCompName.toLowerCase().includes(searchTerm.toLowerCase())
    ).slice(indexOfFirstCompany, indexOfLastCompany);

    // Change page
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    // Handle search input change
    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
        setCurrentPage(1); // Reset pagination to first page when searching
    };

    // Handle delete company
    const handleDeleteCompany = async (id) => {
        try {
            await fetch(`https://api.cloudace.co.uk/delete-company/${id}`, {
                method: 'DELETE'
            });
            // Remove the deleted company from the local state
            setCompanies(companies.filter(company => company.id !== id));
            // Refresh the page
            window.location.reload();
        } catch (error) {
            console.error('Error deleting company:', error);
        }
    };

    const formatDate = (timestamp) => {
        const formattedDate = moment(timestamp).format('YYYY-MM-DD'); // Adjust date format based on the API response
        return formattedDate;
    };

    const totalCompanies = companies.filter(company =>
        company.cCompName.toLowerCase().includes(searchTerm.toLowerCase())
    ).length;

    const totalPages = Math.ceil(totalCompanies / companiesPerPage);

    const renderPageNumbers = () => {
        const pageNumbers = [];
        const maxVisiblePages = 5;

        if (totalPages <= maxVisiblePages) {
            for (let i = 1; i <= totalPages; i++) {
                pageNumbers.push(
                    <Button
                        key={i}
                        variant="outline"
                        colorScheme={currentPage === i ? "blue" : "gray"}
                        onClick={() => paginate(i)}
                    >
                        {i}
                    </Button>
                );
            }
        } else {
            const startPage = Math.max(1, currentPage - 2);
            const endPage = Math.min(totalPages, currentPage + 2);

            for (let i = startPage; i <= endPage; i++) {
                pageNumbers.push(
                    <Button
                        key={i}
                        variant="outline"
                        colorScheme={currentPage === i ? "blue" : "gray"}
                        onClick={() => paginate(i)}
                    >
                        {i}
                    </Button>
                );
            }

            if (currentPage > 3) {
                pageNumbers.unshift(
                    <Button
                        key={1}
                        variant="outline"
                        colorScheme={currentPage === 1 ? "blue" : "gray"}
                        onClick={() => paginate(1)}
                    >
                        1
                    </Button>
                );
                if (currentPage > 4) {
                    pageNumbers.splice(1, 0, <span key="ellipsis1">...</span>);
                }
            }

            if (currentPage < totalPages - 2) {
                pageNumbers.push(
                    <span key="ellipsis2">...</span>,
                    <Button
                        key={totalPages}
                        variant="outline"
                        colorScheme={currentPage === totalPages ? "blue" : "gray"}
                        onClick={() => paginate(totalPages)}
                    >
                        {totalPages}
                    </Button>
                );
            }
        }

        return pageNumbers;
    };

    return (
        <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
            <Card>
                <Stack spacing={4} direction="row" align="center">
                    <Button variant="brand" size="sm">
                        <NavLink to='company-add' fontSize="sm">Add Company</NavLink>
                    </Button>
                </Stack>
                <br/>
                <input
                    type="text"
                    placeholder="Search companies"
                    value={searchTerm}
                    onChange={handleSearchChange}
                />
                <br/>
                <Table variant="striped" colorScheme="brand">
                    <Thead>
                        <Tr>
                            <Th>SR</Th>
                            <Th>Company Name</Th>
                            <Th>Contact Person</Th>
                            <Th>Email Address</Th>
                            <Th>Mobile Number</Th>
                            <Th>Action</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {currentCompanies.map((company, index) => (
                            <Tr key={company.id}>
                                <Td>{indexOfFirstCompany + index + 1}</Td>
                                <Td>{company.cCompName}</Td>
                                <Td>{company.cContactPerson}</Td>
                                <Td>{company.cCompEmail}</Td>
                                <Td>{company.cContractNumber}</Td>
                                <Td>
                                    <Menu>
                                        <MenuButton as={Button} rightIcon={<MdArrowDropDown />}>
                                            Actions
                                        </MenuButton>
                                        <MenuList>
                                            <NavLink to={`/admin/company-edit/${company.id}`}><MenuItem>Edit</MenuItem></NavLink>
                                            <MenuItem onClick={() => handleDeleteCompany(company.id)}>Delete</MenuItem>
                                        </MenuList>
                                    </Menu>
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
                <Stack direction="row" justify="center" mt={4}>
                    <Button
                        onClick={() => paginate(currentPage - 1)}
                        disabled={currentPage === 1}
                    >
                        Previous
                    </Button>
                    {renderPageNumbers()}
                    <Button
                        onClick={() => paginate(currentPage + 1)}
                        disabled={currentPage === totalPages}
                    >
                        Next
                    </Button>
                </Stack>
            </Card>
        </Box>
    );
}
