import React, { useState, useEffect } from 'react';
import {
    Box,
    Link,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Button,
    Stack,
    Input,
    useBreakpointValue
} from "@chakra-ui/react";
import Card from "components/card/Card";
import moment from 'moment';

export default function EmployeeReport() {
    const [memberships, setMemberships] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [membershipsPerPage] = useState(10);
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        // Fetch membership data from the server
        const fetchMemberships = async () => {
            try {
                const response = await fetch('https://api.cloudace.co.uk/get-membership');
                if (response.ok) {
                    const data = await response.json();
                    setMemberships(data);
                } else {
                    console.error('Failed to fetch membership data');
                }
            } catch (error) {
                console.error('Error fetching membership data:', error);
            }
        };

        fetchMemberships();
    }, []);

    // Get current memberships based on search term and pagination
    const filteredMemberships = memberships.filter(membership =>
        membership.cCompName && membership.cCompName.toLowerCase().includes(searchTerm.toLowerCase())
    );
    const indexOfLastMembership = currentPage * membershipsPerPage;
    const indexOfFirstMembership = indexOfLastMembership - membershipsPerPage;
    const currentMemberships = filteredMemberships.slice(indexOfFirstMembership, indexOfLastMembership);

    // Change page
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    // Handle search input change
    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
        setCurrentPage(1); // Reset pagination to first page when searching
    };

    // Format timestamp to date
    const formatDate = (timestamp) => {
        const date = new Date(timestamp);
        return date.toLocaleDateString();
    };

    // Function to export data to CSV
    const exportToCsv = () => {
        const csvContent = [
            'Company Name,Start Date,Amount,Created Date'
        ];

        filteredMemberships.forEach(membership => {
            const csvRow = [
                membership.cCompName,
                formatDate(membership.mStartDate),
                `£${membership.mAmnt}`,
                formatDate(membership.mCreateTime)
            ];
            csvContent.push(csvRow.join(','));
        });

        const csvString = csvContent.join('\n');
        const blob = new Blob([csvString], { type: 'text/csv' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'membership_list.csv';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };
    const inputWidth = useBreakpointValue({ base: '100%', md: 'auto' });

    return (
        <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
            <Link
                color='black'
                bg='inherit'
                borderRadius='inherit'
                fontWeight='bold'
                fontSize={{ base: "24px", md: "34px" }}
                _active={{
                    bg: 'inherit',
                    transform: 'none',
                    borderColor: 'transparent'
                }}
                _focus={{
                    boxShadow: 'none'
                }}>
                Sales Report
            </Link>
            <Card>
                <Stack spacing={4} direction="row" align="center">

                    <Button variant="brand" size="sm" onClick={exportToCsv}>
                        Export CSV
                    </Button>
                    <Input
                        type="text"
                        value={searchTerm}
                        onChange={handleSearchChange}
                        placeholder="Search by name or email"
                        maxW={inputWidth}
                    />
                </Stack>
                <Box overflowX="auto">
                    <Table variant="striped" colorScheme="brand" mt={4} minW="100%">
                        <Thead>
                            <Tr>
                                <Th>Company Name</Th>
                                <Th>Start Date</Th>
                                <Th>Amount</Th>
                                <Th>Created Date</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                        {currentMemberships.map((membership, index) => (
                          <Tr key={membership.id}>
                            <Td>{membership.cCompName}</Td>
                            <Td>{formatDate(membership.mStartDate)}</Td>
                            <Td>£{membership.mAmnt}</Td>
                            <Td>{formatDate(membership.mCreateTime)}</Td>
                          </Tr>
                        ))}
                         
                        </Tbody>
                    </Table>
                </Box>
                <Stack direction="row" justify="center" mt={4}>
                    {Array.from({ length: Math.ceil(filteredMemberships.length / membershipsPerPage) }, (_, index) => (
                        <Button
                            key={index}
                            variant={currentPage === index + 1 ? "solid" : "outline"}
                            onClick={() => paginate(index + 1)}
                        >
                            {index + 1}
                        </Button>
                    ))}
                </Stack>
            </Card>
        </Box>
    );
}
