
import {
    Box,
    Link,
    Grid,
    Form,
    FormControl,
    FormLabel,
    Input,
    Select,
    Textarea,
    FormErrorMessage,
    FormHelperText,
    Button,
} from "@chakra-ui/react";
import Card from "components/card/Card"
import { loadStripe } from '@stripe/stripe-js';
import { useHistory } from 'react-router-dom';
import moment from 'moment'; // Import moment for date/time formatting

import React, { useState, useEffect } from 'react';
import { MdArrowDropDown, MdForkRight } from "react-icons/md";
import axios from 'axios';

export default function Overview() {
    const [formData, setFormData] = useState({
        mCompanyId: '',
        mStartDate: '',
        mAmnt: '',
      });
      const [companies, setCompanies] = useState([]);
      const [mRefNo, setMRefNo] = useState('');
    
      useEffect(() => {
        // Fetch company data from the server
        const fetchCompanies = async () => {
          try {
            const response = await fetch('https://api.cloudace.co.uk/get-company');
            if (response.ok) {
              const data = await response.json();
              setCompanies(data);
            } else {
              console.error('Failed to fetch company data');
            }
          } catch (error) {
            console.error('Error fetching company data:', error);
          }
        };
    
        fetchCompanies();
      }, []);
    
      const handleSubmit = async (e) => {
        e.preventDefault();
    
        try {
          const stripe = await loadStripe('pk_live_51OrY6307TGZxspaWSUx9pvDvgQJaIomWXH2WO8akfJVxAcdk3WMaFBvXAg0MUoiyGSxLAE8mxdOfUbus1q6rFyeu00rqexb96K');
    
          // Fetch the last membership ID
          const response = await fetch('https://api.cloudace.co.uk/get-membership');
          if (!response.ok) {
            throw new Error('Failed to fetch last membership ID');
          }
          const data = await response.json();
          const lastMembershipId = data.lastMembershipId;
    
          // Generate the next reference number based on the last membership ID
          const nextMembershipId = lastMembershipId + 1;
          const currentYear = new Date().getFullYear().toString().slice(-2);
          const nextRefNumber = `INV-${currentYear}-CLDACE-${nextMembershipId.toString().padStart(4, '0')}`;
    
          setMRefNo(nextRefNumber); // Set mRefNo separately for rendering
    
          // Send form data to your server to create a membership
          const addMembershipResponse = await fetch('https://api.cloudace.co.uk/add-membership', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              ...formData,
              mRefNo: nextRefNumber // Use the generated reference number
            })
          });
    
          if (!addMembershipResponse.ok) {
            throw new Error('Failed to add membership');
          }
    
          const sessionData = await addMembershipResponse.json();
    
          // Redirect to Stripe Checkout for subscription confirmation
          const { error } = await stripe.redirectToCheckout({
            sessionId: sessionData.sessionId,
          });
    
          if (error) {
            console.error('Failed to redirect to Stripe Checkout:', error);
          }
        } catch (error) {
          console.error('Error creating membership:', error);
        }
      };
    
      const handleCompanyChange = (e) => {
        setFormData({ ...formData, mCompanyId: e.target.value });
      };
    
      const handleStartDateChange = (e) => {
        setFormData({ ...formData, mStartDate: e.target.value });
      };
    
      const handleAmountChange = (e) => {
        setFormData({ ...formData, mAmnt: e.target.value });
      };


    return (
        <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
            <Card>
                <form onSubmit={handleSubmit}>

                    <Grid templateColumns="repeat(2, 1fr)" gap={4}>
                        <FormControl id="employee">
                            <FormLabel>Select Company:</FormLabel>
                            <Select
                            name="mCompanyId"
                            value={formData.mCompanyId}
                            onChange={handleCompanyChange}
                            required
                            className="form-control"
                          >
                            <option value="">Select Company</option>
                            {companies.map((company) => (
                              <option key={company.id} value={company.id}>
                                {company.cCompName}
                              </option>
                            ))}
                          </Select>
                        </FormControl>

                        <FormControl id="employee">
                            <FormLabel>Start Date:</FormLabel>
                            <Input
                            type="date"
                            name="mStartDate"
                            value={formData.mStartDate}
                            onChange={handleStartDateChange}
                            required
                            className="form-control"
                          />
                        </FormControl>

                        <FormControl id="employee">
                            <FormLabel>Amount:</FormLabel>
                            <Input
                            type="number"
                            name="mAmnt"
                            value={formData.mAmnt}
                            onChange={handleAmountChange}
                            required
                            className="form-control"
                          />
                        </FormControl>

                        <FormControl id="employee">
                            <FormLabel>Reference Number:</FormLabel>
                            <Input
                            type="text"
                            name="mRefNo"
                            value={mRefNo} // Display the generated reference number
                            readOnly
                            className="form-control"
                          />
                        </FormControl>


                    </Grid>
                    

                    <Button
                        mt={4}
                        colorScheme="brand"
                        type="submit"
                    >
                        Submit
                    </Button>
                </form>
            </Card>
        </Box >

    );
}
