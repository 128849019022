import { Box, Grid, FormControl, FormLabel, Input, Select, Button, Link } from "@chakra-ui/react";
import Card from "components/card/Card";
import { useHistory, useParams } from 'react-router-dom';
import React, { useState, useEffect } from 'react';

export default function Overview() {
  const [employee, setEmployee] = useState(null);
  const [formData, setFormData] = useState({
    cFullName: '',
    cEmail: '',
    cPassword: ''
  });

  const { id } = useParams(); // Extract the employee ID from the URL parameters

  useEffect(() => {
    const fetchEmployee = async () => {
      try {
        const response = await fetch(`https://api.cloudace.co.uk/creds/${id}`);
        if (response.ok) {
          const data = await response.json();
          setEmployee(data);
          setFormData({
            cFullName: data.cFullName || '',
            cEmail: data.cEmail || '',
            cPassword: data.cPassword || ''
          });
        } else {
          console.error('Failed to fetch employee data');
        }
      } catch (error) {
        console.error('Error fetching employee data:', error);
      }
    };

    fetchEmployee();
  }, [id]);

  const history = useHistory();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await saveFormDataToServer(formData);
      history.push('/admin/default');
    } catch (error) {
      console.error('Error saving data:', error);
    }
  };

  const saveFormDataToServer = async (data) => {
    const response = await fetch(`https://api.cloudace.co.uk/edit-mycompany/${id}`, {
      method: 'PUT', // Assuming you are using PUT method to update data
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    });

    if (!response.ok) {
      throw new Error('Failed to save data');
    }
  };
  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <Link
        color='black'

        bg='inherit'
        borderRadius='inherit'
        fontWeight='bold'
        fontSize='34px'
        _active={{
          bg: 'inherit',
          transform: 'none',
          borderColor: 'transparent'
        }}
        _focus={{
          boxShadow: 'none'
        }}>
        My Profile
      </Link>

      <Grid
        templateColumns={{ base: '1fr', md: 'repeat(3, 1fr)' }}
        gap={{ base: '10px', xl: '10px' }}
        justifyContent="center" // Center content horizontally
      >
        {/* Full-width Card */}
        <Card minHeight={{ base: 'auto', md: 'auto' }} p={{ base: '10px', md: '20px' }}>
          <form onSubmit={handleSubmit}>

            <Grid templateColumns="repeat(3, 1fr)" gap={4}>


              <FormControl id="location" gridColumn={{ base: 'span 3', md: 'span 3' }}>
                <FormLabel>  Company Name: </FormLabel>
                <Input
                  type="text"
                  name="cFullName"
                  value={formData.cFullName}
                  onChange={handleChange}
                  required
                  className='form-control'
                />
              </FormControl>

              <FormControl id="location" gridColumn={{ base: 'span 3', md: 'span 3' }}>
                <FormLabel> Email:</FormLabel>
                <Input
                  type="text"
                  name="cEmail"
                  value={formData.cEmail}
                  onChange={handleChange}
                  required
                  className='form-control'
                />
              </FormControl>

              <FormControl id="location" gridColumn={{ base: 'span 3', md: 'span 3' }}>
                <FormLabel> Password:</FormLabel>
                <Input
                  type="text"
                  name="cPassword"
                  value={formData.cPassword}
                  onChange={handleChange}
                  required
                  className='form-control'
                />
              </FormControl>





            </Grid>

            <Button
              mt={4}
              colorScheme="brand"
              type="submit"
            >
              Update Your Data
            </Button>
          </form>
        </Card>

        {/* Two Cards in a Row */}

      </Grid>
    </Box>
  );
}
