import React from "react";

import { Icon } from "@chakra-ui/react";
import {
  MdBarChart,
  MdPerson,
  MdHome,
  MdLock,
  MdOutlineShoppingCart,
  MdCalendarViewWeek,
  MdNote,
  MdNoteAdd,
  MdListAlt,
  MdLocationPin,
  MdPerson4,
  MdPending,
  MdOutbox,
} from "react-icons/md";

// Admin Imports
import MainDashboard from "views/admin/default";
import Profile from "views/admin/profile";




import Company from "views/admin/Company";
import CompanyAdd from "views/admin/Company/CompanyAdd";
import CompanyEdit from "views/admin/Company/CompanyEdit";

import Invoice from "views/admin/Invoice";
import InvoiceAdd from "views/admin/Invoice/InvoiceAdd";
import InvoicePrint from "views/admin/Invoice/InvoicePrint";


import ReportUser from "views/admin/Report/UserReport";
import ReportSales from "views/admin/Report/SalesReport";
import ReportExpiry from "views/admin/Report/ExpiryReport";



import SignInCentered from "views/auth/signIn";
import ForgotPassword from "views/auth/signIn/ForgotPassword";
import ResetPassword from "views/auth/signIn/ResetPassword";


const routes = [
  {
    name: "Dashboard",
    layout: "/admin",
    path: "/default",
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: MainDashboard,
  },
 
  
  {
    name: "Registered Users",
    layout: "/admin",
    icon: <Icon as={MdPerson4} width="20px" height="20px" color="inherit" />,
    path: "/company",
    component: Company,
  },

  {
    name: "Company Add",
    layout: "/admin",
    icon: <Icon as={MdNote} width="20px" height="20px" color="inherit" />,
    path: "/company-add",
    component: CompanyAdd,
    hidden: true,
  },
  {
    name: "Company Edit",
    layout: "/admin",
    icon: <Icon as={MdNote} width="20px" height="20px" color="inherit" />,
    path: "/company-edit/:id",
    component: CompanyEdit,
    hidden: true,
  },





  {
    name: "Invoices",
    layout: "/admin",
    icon: <Icon as={MdOutlineShoppingCart} width="20px" height="20px" color="inherit" />,
    path: "/invoice",
    component: Invoice,
  },

  {
    name: "Company Add",
    layout: "/admin",
    icon: <Icon as={MdNote} width="20px" height="20px" color="inherit" />,
    path: "/invoice-add",
    component: InvoiceAdd,
    hidden: true,
  },
  {
    name: "Company Edit",
    layout: "/admin",
    icon: <Icon as={MdNote} width="20px" height="20px" color="inherit" />,
    path: "/invoice-print/:id",
    component: InvoicePrint,
    hidden: true,
  },



  {
    name: "User Report",
    layout: "/admin",
    icon: <Icon as={MdListAlt} width="20px" height="20px" color="inherit" />,
    path: "/user-report",
    component: ReportUser,
  },

  {
    name: "Sales Report",
    layout: "/admin",
    icon: <Icon as={MdListAlt} width="20px" height="20px" color="inherit" />,
    path: "/sales-report",
    component: ReportSales,
  },

  
  {
    name: "Expiry Report",
    layout: "/admin",
    icon: <Icon as={MdListAlt} width="20px" height="20px" color="inherit" />,
    path: "/expiry-report",
    component: ReportExpiry,
  },




  
  {
    name: "My Profile",
    layout: "/admin",
    path: "/profile/:id",
    icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
    component: Profile,
    hidden: true,
  },
  {
    name: "Log out",
    layout: "/auth",
    path: "/sign-in",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: SignInCentered,
    hidden: true,
  },
  {
    name: "Forgot Password",
    layout: "/auth",
    path: "/forgot-password",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: ForgotPassword,
    hidden: true,
  },
  {
    name: "Reset Password",
    layout: "/auth",
    path: "/reset-password/:token",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: ResetPassword,
    hidden: true,
  },
 

];

export default routes;
