import React, { useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import {
    Box,
    Button,
    Checkbox,
    Flex,
    FormControl,
    FormLabel,
    Heading,
    Icon,
    Input,
    InputGroup,
    InputRightElement,
    Text,
    useColorModeValue,
} from '@chakra-ui/react';
import DefaultAuth from 'layouts/auth/Default';
import illustration from 'assets/img/auth/auth.png';
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import { useParams } from 'react-router-dom';
import { RiEyeCloseLine } from 'react-icons/ri';

function SignIn() {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const history = useHistory();
    const { token } = useParams(); // Extract 'token' from URL params

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (password !== confirmPassword) {
            setError('Passwords do not match');
            return;
        }

        try {
            const endpoint = `https://api.cloudace.co.uk/update-password/${token}`;

            const response = await fetch(endpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ newPassword: password })
            });

            if (response.ok) {
                const data = await response.json();
                console.log(data.message); // Log success message
                history.push('/auth/sign-in');// Redirect to login page after resetting password
            } else {
                const errorData = await response.json();
                setError(errorData.error || 'Failed to reset password');
            }
        } catch (error) {
            console.error('Reset password error:', error);
            setError('An unexpected error occurred');
        }
    };

    const [showPassword, setShowPassword] = useState(false);
    const togglePasswordVisibility = () => setShowPassword(!showPassword);

    const textColor = useColorModeValue('navy.700', 'white');
    const textColorSecondary = 'gray.400';
    const brandStars = useColorModeValue('brand.500', 'brand.400');

    return (
        <DefaultAuth illustrationBackground={illustration} image={illustration}>
            <Flex
                maxW={{ base: '100%', md: 'max-content' }}
                w='100%'
                mx={{ base: 'auto', lg: '0px' }}
                me='auto'
                h='100%'
                alignItems='start'
                justifyContent='center'
                mb={{ base: '30px', md: '60px' }}
                px={{ base: '25px', md: '0px' }}
                mt={{ base: '40px', md: '14vh' }}
                flexDirection='column'
            >
                <Box me='auto'>
                    <Heading color={textColor} fontSize='36px' mb='10px'>
                        Reset Your Password!
                    </Heading>
                    <Text
                        mb='36px'
                        ms='4px'
                        color={textColorSecondary}
                        fontWeight='400'
                        fontSize='md'
                    >
                        Please Enter the new password!
                    </Text>
                </Box>
                <Flex
                    direction='column'
                    w={{ base: '100%', md: '420px' }}
                    maxW='100%'
                    background='transparent'
                    borderRadius='15px'
                    mx={{ base: 'auto', lg: 'unset' }}
                    me='auto'
                    mb={{ base: '20px', md: 'auto' }}
                >
                    <form onSubmit={handleSubmit}>
                        <FormControl>
                            <FormLabel htmlFor='password' color={textColor} mb='8px'>
                                New Password<span style={{ color: brandStars }}>*</span>
                            </FormLabel>
                            <Input
                                id='password'
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />
                        </FormControl>
                        <FormControl mt='24px'>
                            <FormLabel htmlFor='confirmPassword' color={textColor} mb='8px'>
                                Confirm Password<span style={{ color: brandStars }}>*</span>
                            </FormLabel>
                            <InputGroup>
                                <Input
                                    id='confirmPassword'
                                    type={showPassword ? 'text' : 'password'}
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                    required
                                />
                                <InputRightElement>
                                    <Icon
                                        as={showPassword ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                                        color={textColorSecondary}
                                        _hover={{ cursor: 'pointer' }}
                                        onClick={togglePasswordVisibility}
                                    />
                                </InputRightElement>
                            </InputGroup>
                        </FormControl>
                        {error && (
                            <Text color='red.500' mt='2' fontSize='sm'>
                                {error}
                            </Text>
                        )}
                        <Button
                            type='submit'
                            variant='brand'
                            mt='24px'
                            w='100%'
                            h='50px'
                            fontSize='sm'
                            fontWeight='500'
                        >
                            Reset Password
                        </Button>
                    </form>
                
                </Flex>
            </Flex>
        </DefaultAuth>
    );
}

export default SignIn;
